export const infoConfig = {
  delivery: {
    title: "Доставка",
    paragraphs: [
      "Доставка осуществляется путем <b>почтовой</b> пересылки.",
      "После оплаты вы получите письмо с <b>подтверждением заказа</b>.",
      "В течении <b>1-5</b> дней вам придет письмо с трек номером ващего заказа.",
      "Сроки доставки зависят от вашего местоположения, а также <b>природной/политической/геополитической обстановки</b> в местах отправления и доставки",
    ],
  },
  contacts: {
    title: "Контакты",
    paragraphs: [
      "<b>Email</b>: <a href='mailto:gripster.pro.rf@gmail.com'>gripster.pro.rf@gmail.com</a>",
      "<b>ИНН</b>: 650405030474",
      "<b>Telegram</b>: DedStriger",
    ],
  },
};
